<script lang="ts">
import { ref } from 'vue';

export default {
    props: ['listing'],
    setup() {
        const isOpen = ref(false);
        const appId = useRuntimeConfig().public.appId;
        return { isOpen, appId }
    }
}
</script>

<template>
    <div>
        <w-button class="flex justify-center text-sm w-full gap-1 text-indigo-700 bg-indigo-100 py-2 px-2 rounded hover:bg-indigo-200" @click="isOpen = true"><i class="fa-solid fa-envelope"></i> Email</w-button>

        <w-modal v-model="isOpen" :transition="false" class="w-full">
            <div class="p-3 text-center flex flex-col gap-3">
                <h3 class="text-gray-900 text-2xl my-3">Send a request</h3>
                <img :src="`/images/${appId}/logo-blue.webp`" :alt="$t('vartur')" class="my-2 w-auto h-8 object-contain">
                <layout-email-form :listing="listing"></layout-email-form>
            </div>
        </w-modal>
    </div>
</template>