<template>
	<div>
		<div v-for="(item, index) in items" :key="index">
			<div @click="toggle(item.slot)">
				<!-- Slot for custom header -->
				<slot name="default" :item="item" :index="index" :open="opened[item.slot]">
					<h4>{{ item.label }}</h4>
				</slot>
				<!-- Slot for description -->
				<transition name="accordion"
					@before-enter="beforeEnter"
					@enter="enter"
					@before-leave="beforeLeave"
					@leave="leave">
					<div v-if="opened[item.slot] == true" ref="accordionContent" class="overflow-hidden">
						<slot :name="item.slot" :item="item"></slot>
					</div>
				</transition>

			</div>


		</div>
	</div>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
	items: {
		type: Array,
		required: true,
	},
});


const opened = ref({
	description: true,
	features: true,
});

const toggle = (p) => {
	opened.value[p] = !opened.value[p]
};

// Transition hooks for smooth height animation
const beforeEnter = (el) => {
	el.style.height = '0';
};

const enter = (el) => {
	el.style.height = el.scrollHeight + 'px';
	el.style.transition = 'height 0.3s ease';
};

const beforeLeave = (el) => {
	el.style.height = el.scrollHeight + 'px';
};

const leave = (el) => {
	el.style.height = '0';
	el.style.transition = 'height 0.3s ease';
};

</script>