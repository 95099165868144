<script setup>
import { ref, reactive } from 'vue';

// Props
const props = defineProps(["items"]);

// Example reactive data or ref (if needed)
const tap = ref('Details');

</script>
<template>
	<div>
		<!-- Default Slot -->
		<div class="flex pb-4 gap-3">
			<button v-for="item in items" :class="[
				'relative inline-flex items-center justify-center flex-shrink-0 w-[49%] text-indigo-800 transition-colors duration-200 ease-out h-8 px-3 text-sm font-medium rounded-md  bg-indigo-100 border border-gray-200 py-6',
				item.disabled ? 'disabled:cursor-not-allowed disabled:opacity-75' : '',
				tap != item.label ? 'opacity-100 bg-transparent' : ''
			]"
				:disabled="item.disabled" @click="tap = item.label">
				<slot name="default" :item="item"></slot>
			</button>
		</div>

		<!-- Ad Details Slot -->
		<slot v-if="tap == 'Details'" name="ad_details" :item="items[0]"></slot>

		<!-- Location Slot (conditionally shown) -->
		<slot v-if="tap == 'Location'" name="location"></slot>

	</div>
</template>
