<script setup lang="ts">
import { useFiltersStore } from '~/stores/filters.store';
import { useMetaStore } from '~/stores/meta.store';
import helper from '~/resources/js/libraries/helper';
import { ref } from 'vue';
import { useRoute, useRuntimeConfig } from 'nuxt/app';
import FavoriteCard from './partials/favorite-card.vue'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const isOpen = ref(false);
const config = useRuntimeConfig();
const filtersStore = useFiltersStore();
const metaStore = useMetaStore();
const currency = helper.cookies.currency();
const { params, fullPath } = useRoute();
const { locale } = useI18n();
const { listing, breadcrumbs, meta } = await filtersStore.fetchListing(params.slug);

if (!listing) {
    showError({
        statusCode: 404,
        statusMessage: "Page Not Found"
    });
}

const displayFeatureName = (feature: {[key: string]: any}) => {
    if (feature.measurement) {
        let unit = feature.measurement.units.find(
            (u: any) => u.countries_ids.includes(listing.country_id)
        )
        || feature.measurement.units.find(
            (u: any) => u._id == feature.measurement.default_unit_id
        )
        return unit && unit?.name[locale] || unit?.default_name || feature.name
    }
    
    return feature.input_type === 'number' ? feature.name : ''

}

metaStore.setMeta(meta);

const breadcrumbSeo = breadcrumbs?.map((b: any, i: number) => ({
    "@type": "ListItem",
    "@position": i + 1,
    "name": b.name,
    "item": new URL(b.url, config.public.websiteUrl as string).href,
}))

const additionalPropertySeo = listing.features?.base_features?.map((feature: {[key: string]: string|number}) => ({
    "@type": "PropertyValue",
    "value": feature.value,
    "name": feature.name
}))

useSchemaOrg({
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    "itemListElement": breadcrumbSeo
})

useSchemaOrg({
    "@context": "http://schema.org/",
    "@type": "Product",
    "name": listing.title,
    "image": listing.picture,
    "description": listing.description,
    "offers": {
        "@type": "Offer",
        "url": new URL(fullPath, config.public.websiteUrl as string).href,
        "priceCurrency": currency.value.code,
        "availability": "http://schema.org/InStock",
        "price": listing.sale_price,
        "seller": {
            "@type": "Organization",
            "name": listing.shop.name
        },
        "hasMerchantReturnPolicy": {
            "@type": "MerchantReturnPolicy",
            "applicableCountry": "tr",
            "returnPolicyCategory": "https://schema.org/MerchantReturnFiniteReturnWindow",
            "merchantReturnDays": 0,
            "returnMethod": "https://schema.org/ReturnByMail",
            "returnFees": "https://schema.org/FreeReturn"
        }
    }
})

useSchemaOrg(
    {
        "@context": "http://schema.org/",
        "@type": "Residence",
        "address": {
            "@type": "PostalAddress",
            "addressCountry": listing.country_name,
            "addressRegion": listing.city_name
        },
        "mainEntityOfPage": new URL(fullPath, config.public.websiteUrl as string).href,
        "url": new URL(fullPath, config.public.websiteUrl as string).href,
        "name": listing.title,
        "additionalProperty": additionalPropertySeo
    },
)

const items=[{
                slot: 'ad_details',
                label: t('client.details'),
                selected:true,
                }, {
                    slot: 'location',
                    label: t('client.location'),
                    selected:false,
                    disabled: listing.location && ( listing.location.lat && listing.location.lng ) ? false : true
            }]
</script>

<template>
     <LayoutAppHead></LayoutAppHead>
    <NuxtLayout name="desktop-default">
        <div class="container mx-auto max-w-7xl px-4 sm:px-2 lg:px-8 mb-8">
            <nav class="flex my-4" aria-label="Breadcrumb">
                <breadcrumb :data="breadcrumbs"></breadcrumb>
            </nav>
            <div class="grid grid-cols-4 gap-4 mx-0">
                <div class="col-span-3">
                    <div class="bg-white p-3 rounded-xl mb-4">
                        <div class="flex items-center justify-between border-b border-gray-300 pb-3">
                            <h2 class="text-2xl text-indigo-900 w-3/4" itemprop="name">{{ listing.title }}</h2>
                            <div class="flex items-center gap-4">
                                <span class="text-xl text-gray-900 font-medium">
                                    {{ helper.formatPrice(listing.sale_price) }}
                                    {{ currency.symbol }}
                                </span>
                                <FavoriteCard :listing-id="listing._id" />
                            </div>
                        </div>
                        <div class="grid grid-cols-3 gap-3 mt-4">
                            <lightbox :images="listing.gallery_pictures"></lightbox>
                            <div class="col-span-2 -mt-16" v-if="listing.features">
                                <div class="flex text-center">
                                    <div class="grid grid-flow-row border-r px-5">
                                        <span class="mb-3 text-gray-600">{{ $t('client.listing_date') }}</span>
                                        <span class="text-xs text-gray-500">{{ listing.listing_date }}</span>
                                    </div>
                                    <div class="grid grid-flow-row border-r px-3" v-for="feature in listing.features.base_features" :key="feature.slug">
                                        <span class="mb-3 text-gray-600">
                                            <i :class="`${feature.icon} fa-xl mb-3`"></i>
                                        </span>
                                        <span class="text-xs text-gray-500">
                                            {{ feature.value }}
                                            {{ displayFeatureName(feature) }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="-mt-16 flex ms-auto">
                                <span class="text-xs text-gray-600"># {{ listing._id }}</span>
                            </div>
                        </div>

    
                        <div class="my-8">
                            <w-tabs :items="items">
                                <template #default="{ item }">
                                    <p class="text-base py-2 px-6">{{ item.label }}</p>
                                </template>

                                <template #ad_details="{ item }">
                                    <div class="">
                                        <w-accordion multiple default-open :items="[{ label: $t('client.description'), slot: 'description' }, { label: $t('client.features'), slot: 'features' }]">
                                            <template #default="{ item, index, open }">
                                                <div class="border border-gray-200 bg-gray-50 mb-4 rounded-md overflow-hidden">
                                                    <h4 class="p-3 bg-gray-100 text-gray-800">{{ item.label }}</h4>
                                                </div>
                                            </template>
                                        
                                            <template #description>
                                                <div class="p-3 text-sm text-gray-600" v-html="listing.description"></div>
                                            </template>
                                            <template #features="{ item }" v-if="item.selected">
                                                <div class="p-3 text-sm text-gray-600" v-if="listing.features.multi_features?.filter((f: any) => f.input_type != 'image')?.length">
                                                    <div class="flex flex-col mb-3" v-for="feature in listing.features.multi_features.filter((f: any) => f.input_type != 'image')">
                                                        <h5 class="font-bold text-indigo-800 mb-3">{{ feature.name }}</h5>
                                                        <div class="grid grid-cols-3 gap-3">
                                                            <div class="flex items-center" v-for="sub_feature in feature.value">
                                                                <i :class="sub_feature.icon"></i>
                                                                <span class="text-gray-600 text-sm ms-1">{{ sub_feature.value }}</span>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="flex flex-wrap gap-2" v-if="listing.features.multi_features?.filter((f: any) => f.input_type == 'image')?.length">
                                                    <div class="flex flex-col gap-2 mb-1" v-for="feature in listing.features.multi_features.filter((f: any) => f.input_type == 'image')">
                                                        <h5 class="font-medium text-indigo-800 mb-1">{{ feature.name }}</h5>
                                                        <img class="w-44 h-36 rounded-md object-cover" :src="feature.value" itemprop="thumbnailUrl" />
                                                    </div>
                                                </div>
                                            </template>
                                        </w-accordion>
                                    </div>
                                </template>

                                <template #location v-if="listing.location && ( listing.location.lat && listing.location.lng )">
                                    <shared-map-i-frame :lng="listing.location.lng" :lat="listing.location.lat" addMark />
                                </template>
                            </w-tabs>
                        </div>

                        <div class="w-full" v-if="listing.video">
                            <video height="400" class="mx-auto w-full" style="height:revert-layer;" controls>
                                <source :src="listing.video" type="video/mp4">
                            </video>
                        </div>
                    </div>
                    <h3 class="text-3xl text-indigo-900 my-8 font-bold">{{ $t('client.similar_listings') }}</h3>
                    <div class="grid grid-cols-2 gap-5">
                        <div v-for="similar in listing.similar_listings" itemscope itemtype="https://schema.org/RealEstateListing">
                            <NuxtLink :to="localePath({ name: 'listings.show', params: { slug: similar.url } })" itemprop="url">
                                <img class="w-full h-56 rounded-tl-2xl rounded-tr-2xl object-cover" :src="`${similar.picture}?w=800&h=500`" itemprop="thumbnailUrl" />
                                <div class="px-12 py-2.5 bg-white rounded-bl-2xl rounded-br-2xl flex-col items-center gap-2.5 flex">
                                    <div class="text-center text-neutral-400 text-xs font-normal leading-none">{{ similar.cities.join(' / ') }}</div>
                                    <div class="text-center text-indigo-900 text-xl font-bold leading-normal">
                                        {{ helper.formatPrice(similar.sale_price) }}
                                        {{ currency.symbol }}
                                    </div>
                                </div>
                            </NuxtLink>
                        </div>
                    </div>
                </div>
                <div class="text-center">
                    <div class="sticky top-0 p-3 rounded-xl bg-white  flex flex-col justify-center ">
                        <img :src="listing.shop.logo" alt="" class="h-20 w-20 rounded object-cover mb-3 mx-auto text-center">
                        <h5 class="text-xl mb-3 font-medium">{{ listing.shop.name }}</h5>
                        <h6 class="text-sm mb-3"><span class="text-xs text-gray-500">{{ $t('client.agent') }}:</span>{{ `${listing.user.first_name} ${listing.user.last_name}` }}</h6>
                        <div class="grid grid-cols-1 gap-2">
                            <w-button class="flex justify-center gap-1 text-sm text-indigo-700 bg-indigo-100 py-2 px-2 rounded hover:bg-indigo-200 mx-auto w-full text-center" @click="isOpen = true"><i class="fa-solid fa-phone"></i>{{ $t('client.call') }}</w-button>

                            <w-modal v-model="isOpen" :transition="false" class="w-full">
                                <div class="p-3 text-center flex flex-col gap-3">
                                    <h3 class="text-gray-900 text-2xl mb-6">{{ $t('client.contact_us') }}</h3>
                                    <p class="text-gray-500 text-sm mb-6">{{ listing.shop.name }}</p>
                                    <div class="text-indigo-700 text-3xl" v-if="listing.shop.mobile">
                                        <span class="text-xs text-gray-500 me-3">{{ $t('client.work_mobile') }}</span>
                                        <a :href="`tel:+${listing.shop.country_code}${listing.shop.mobile}`">{{ listing.shop.formatted_mobile }}</a>
                                    </div>
                                    <div class="text-indigo-700 text-3xl" v-if="listing.user.show_mobile_in_listings">
                                        <span class="text-xs text-gray-500 me-3">{{ $t('client.agent_mobile') }}</span>
                                        <a :href="`tel:+${listing.user.country_code}${listing.user.mobile}`">{{ listing.user.formatted_mobile }}</a>
                                    </div>
                                    <div class="text-indigo-700 text-3xl" v-if="listing.user.show_whatsapp_in_listings && listing.user.formatted_whatsapp">
                                        <span class="text-xs text-gray-500 me-3">{{ $t('client.whatsapp') }}</span>
                                        <NuxtLink :to="listing.user.whatsapp_link" target="_blank">{{ listing.user.formatted_whatsapp }}</NuxtLink>
                                    </div>
                                    <h6 class="text-sm mb-3 border-y border-gray-300 py-3"><span class="text-xs text-gray-500 me-3">{{ $t('client.agent') }}:</span>{{ `${listing.user.first_name} ${listing.user.last_name}` }}</h6>
                                    <div class="mt-4 text-gray-600">
                                        {{ $t('client.property_reference') }}
                                    <br>    <span class="font-medium text-gray-900"># {{ listing._id }}</span> 
                                    <br>    {{ $t('client.when_calling_us') }}
                                    </div>
                                </div>
                            </w-modal>
                            <modal-contact :listing="listing"></modal-contact>
                            <a :href="listing.whatsapp_message" class="text-sm text-teal-600 bg-teal-100 py-2 px-2 rounded" target="_blank"><i class="fa-brands fa-whatsapp fa-xl"></i> {{ $t('client.whatsapp') }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </NuxtLayout>
</template>